import React, { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { getExcelTable } from '@services/crossword'

import WarningNote from '@objects/warningnote'
import { Button, Typography } from '@mui/material'
import { Box } from '@mui/system'

export default function ParticipantsDataPage() {
  const intl = useIntl()
  const [loading, setLoading] = useState(false)

  const openExcel = () => {
    setLoading(true)
    getExcelTable()
      .then((res) => {
        let blob = new Blob([res.data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          }),
          url = window.URL.createObjectURL(blob)
        window.open(url)
      })
      .catch((e) => console.error(e))
      .finally(() => setLoading(false))
  }

  function renderCards() {
    return (
      <Box>
        <Button variant="contained" color="secondary" onClick={openExcel}>
          {loading ? (
            <FormattedMessage id="button.loading" />
          ) : (
            <FormattedMessage id="button.download" />
          )}
        </Button>

        <Typography style={{ color: 'white' }}>
          <FormattedMessage id="page.crossword.message" />
        </Typography>
      </Box>
    )
  }
  return (
    <>
      <WarningNote
        text={intl.formatMessage({
          id: 'page.gdpr.note',
        })}
      />
      {renderCards()}
    </>
  )
}
