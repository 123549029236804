import React from 'react'
import PropTypes from 'prop-types'

import { Typography, Box } from '@mui/material'

import { Warning } from '@mui/icons-material'
import { styled } from '@mui/system'

export type WarningNoteProps = AT.IReactPropsDefaults & {
  text: string
}

const IconWarning = styled(Warning)(({ theme }) => ({
  fontSize: '28px',
  color: theme.palette.red[500],
  marginRight: theme.spacing(2),
  marginLeft: theme.spacing(2),
}))

export default function WarningNote({ sxProp, text }: WarningNoteProps) {
  return (
    <Box
      sx={sxProp}
      display={'flex'}
      flexDirection={'row'}
      alignItems={'center'}
      m={8}
    >
      <IconWarning />
      <Typography variant="h5">{text}</Typography>
      <IconWarning />
    </Box>
  )
}
